import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";
export default function VehicleClassification() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("vehicle-classification");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);
  return (
    <>
     <Annimations />
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section pb-5">
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/services-images/vehicle-classification.jpg"
          }
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">
                Vehicle Classification
              </h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2 custom-height-vehicle">
        <div className="container shadow  cia-vehicle-class-container ">
          <div className="row">
            <div className="col-md-7   d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Economy Cars</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  These are compact, fuel-efficient vehicles, ideal for city
                  driving and short trips. They are affordable and easy to park.
                  Examples: Toyota Yaris, Ford Fiesta, Hyundai i10. Usage:
                  Suitable for budget-conscious travelers or solo trips.
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyata-auris.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container zoom-in ">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Compact Cars</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  Slightly larger than economy cars, compact cars offer more
                  space while remaining fuel-efficient. Examples: Honda Civic,
                  Ford Focus, Toyota Corolla. Usage: Ideal for small families or
                  business travelers needing a balance between economy and
                  comfort.
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyata-etios.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container zoom-in ">
          <div className="row">
            <div className="col-md-7   d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Mid-Size (Sedan)</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  These cars offer a comfortable ride with more space for
                  passengers and luggage. Examples: Toyota Camry, Honda Accord,
                  Nissan Altima. Usage: Preferred by families or groups of up to
                  five people.
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/chevrolet-malibu.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container zoom-in ">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Full-Size (Sedan)</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  Full-size sedans provide even more comfort, space, and a
                  smoother ride. Examples: Chevrolet Impala, Ford Taurus,
                  Chrysler 300. Usage: Ideal for longer trips and for those who
                  prioritize comfort.
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/hyundai-verna.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container  zoom-in">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Luxury Cars</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  High-end vehicles that offer premium features, superior
                  performance, and comfort. Examples: Mercedes-Benz E-Class, BMW
                  5 Series, Audi A6. Usage: Popular among business executives
                  and on special occasions.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/lexus-es-350.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container zoom-in ">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">SUVs (Sport Utility Vehicles)</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  SUVs provide higher seating positions, more space, and
                  off-road capability. Examples: Toyota RAV4, Ford Explorer,
                  Hyundai Santa Fe. Usage: Suitable for families, off-road
                  trips, or when more luggage space is required.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyota-fortuner.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container zoom-in ">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Premium SUVs</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  These are luxury SUVs offering advanced features, superior
                  comfort, and more powerful engines. Examples: Range Rover, BMW
                  X5, Audi Q7. Usage: Preferred for premium experiences and
                  off-road adventures with style.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyota-land-cruiser.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2">
        <div className="container shadow cia-vehicle-class-container  zoom-in">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Minivans</h3>
                <p className="mb-4 fw-normal cia-service- discription">
                  Minivans offer maximum seating capacity and storage space,
                  ideal for larger groups or families. Examples: Toyota Sienna,
                  Honda Odyssey, Chrysler Pacifica. Usage: Popular among large
                  families or groups traveling together.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-5   order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyota-hiace.png"
                  }
                  alt="Large"
                  className="img-fluid   px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 px-2">
        <div className="container shadow  cia-vehicle-class-container  zoom-in">
          <div className="row">
            <div className="col-md-7   d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Pickup Trucks</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  Pickup trucks are known for their cargo space and towing
                  capacity. Examples: Ford F-150, Toyota Hilux, Chevrolet
                  Silverado. Usage: Suitable for transporting goods, off-road
                  activities, and rough terrain.
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/toyota-hilux.png"
                  }
                  alt="Large"
                  className="img-fluid px-1  "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 px-2">
        <div className="container shadow  cia-vehicle-class-container  zoom-in">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center order-2 order-md-1">
              <div className="row cia-cars-data">
                <h3 className=" fw-bold mb-3">Convertibles</h3>
                <p className="mb-4 fw-normal cia-service-discription">
                  Convertibles offer an open-top driving experience, combining
                  style and performance. Examples: Ford Mustang Convertible, BMW
                  Z4, Mazda MX-5. Usage: Popular for leisure trips in scenic
                  areas.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-5 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/vehicle-classification/mercedes-benz-c200-cabriolet.png"
                  }
                  alt="Large"
                  className="img-fluid px-1  "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaComp
        tagline="Looking for the Perfect Vehicle for Your Trip?"
        buttonText="Contact Us"
        redirection="/contact-us"
      />{" "}
   
     
      <Footer />
    </>
  );
}
