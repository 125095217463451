import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Mainmenu.css";
import {
  getToken,
  storeLocation,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";

export default function Mainmenu() {
  const activeLinkLocation = useLocation().pathname;
  const userLoggedData = getToken();
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  const toggleNavbar = () => {
    setNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleLogout = async () => {
    removeToken(userLoggedData);
    window.location.href = `/user-login`;
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm bg-light navbar-light tablet-view">
        <div className="container nav-bar-tablet" id="header-width">
          <button
            type="button"
            className={`navbar-toggler ${isNavbarCollapsed ? "collapsed" : ""}`}
            onClick={toggleNavbar}
            id="cia-nav"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className={`collapse navbar-collapse ${
              isNavbarCollapsed ? "" : "show"
            }`}
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  style={{ color: activeLinkLocation === "/" ? "red" : null }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/about-us"
                  style={{
                    color: activeLinkLocation === "/about-us" ? "red" : null,
                  }}
                >
                  About Us
                </Link>
              </li>

              {/* Services Dropdown */}
              <li className="nav-item dropdown menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/our-services"
                  style={{ cursor: "pointer" }}
                >
                  Services
                  <i className="bi bi-chevron-down ms-2"></i>
                </Link>

                <ul className="dropdown-menu cia-header-dropdown">
                  <li>
                    <Link
                      className="dropdown-item cia-sub-dropdown-item"
                      to="/our-services/chauffeur-drive"
                    >
                      Chauffeur Drive
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item cia-sub-dropdown-item"
                      to="/our-services/self-drive"
                    >
                      Self Drive
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item cia-sub-dropdown-item"
                      to="/our-services/car-lease"
                    >
                      Car Lease
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item cia-sub-dropdown-item"
                      to="/our-services/employee-transportation"
                    >
                      Employee Transportation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item cia-sub-dropdown-item"
                      to="/our-services/fleet-management"
                    >
                      Fleet Management
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item menu-alignment main-menu-nav-item">
                <a
                  className="nav-link"
                  href="https://carsinafrica.com/blogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blogs
                </a>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/contact-us"
                  style={{
                    color: activeLinkLocation === "/contact-us" ? "red" : null,
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            {userLoggedData.user_id == null ? (
              <ul className="navbar-nav" id="cia-nav-item">
                <li className="nav-item">
                  <Link to="/user-login" className="nav-link">
                    <button type="button" className="partner-corporate-bg">
                      <div className="d-flex align-items-center">
                        <img
                          src={process.env.PUBLIC_URL + "/images/vector.svg"}
                          alt="Icon"
                          className="mr-2 main-menu-login"
                        />
                        <span id="cia-login-header">Login</span>
                      </div>
                    </button>
                  </Link>
                </li>
                <li className="nav-item" id="button-spacing">
                  <Link to="/user-registration" className="nav-link">
                    <button type="button" className="partner-corporate-bg">
                      <div className="d-flex align-items-center">
                        <img
                          src={process.env.PUBLIC_URL + "/images/vector.svg"}
                          alt="Icon"
                          className="mr-2 btn-main-menu-registration"
                        />
                        <span id="cia-register-header">Register</span>
                      </div>
                    </button>
                  </Link>
                </li>
              </ul>
            ) : (
              <div className="navbar-nav main-menu-dropdown-margin px-2">
                <a
                  href=""
                  className="d-profile text-decoration-none dropdown-toggle text-black"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="bi bi-person-circle text-black"
                    id="profile_circle"
                  />
                  Profile
                </a>
                <ul
                  className="dropdown-menu cia-header-dropdown dropdown-menu-start text-small dashboard-auto-margin"
                  aria-labelledby="dropdownUser1"
                >
                  <li>
                    <Link className="dropdown-item" to="/dashboard">
                      <i className="fas fa-chart-bar me-2"></i> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      <i className="fas fa-user me-2"></i> My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/change-password">
                      <i className="fas fa-lock me-2"></i> Change Password
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      <i className="fas fa-sign-out-alt me-2"></i> Sign out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
