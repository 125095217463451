import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import { Link } from "react-router-dom";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Faq3 from "../../ciaComponents/faqs/Faqs3.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";

export default function ChauffeurDrive() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("chauffeur-drive");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  // For Accordian
  const [showAll, setShowAll] = useState(false);
  const faqs = [
    {
      question: "1. What services does your Chauffeur Drive include?",
      answer:
        "Our Chauffeur Drive service consists of a professional, licensed driver, a luxury car of your taste, and free Wi-Fi and bottled drinks. We provide corporate travel, airport transfers, special events, intra-city as well as inter-city travel. ",
    },
    {
      question: "2. How may I schedule a Chauffeur Drive service? ",
      answer:
        "You may quickly reserve our service by calling our customer care line, via our website or smartphone app. Choose your car, enter your travel information, and then verify the booking. Our staff is on call 24/7 to help with any questions.",
    },
    {
      question: "3. What kind of vehicles are available for Chauffeur Drive? ",
      answer:
        "Our fleet consists of executive sedans, luxury SUVs, and premium vans among other vehicles. One can select a car that fits party size and trip requirements.",
    },
    {
      question: "4. How are the chauffeurs selected and trained?",
      answer:
        "Our chauffeurs are carefully chosen depending on driving ability, professionalism, and experience. Regular training helps them to guarantee that they satisfy high criteria of safety, customer service, and local area expertise.",
    },
    {
      question: "5. Describe your price policy.",
      answer:
        "The type of vehicle, length of the service, and distance traveled all affect our cost. We provide open pricing free of hidden fees. Booking using our system will earn you an instantaneous quotation.",
    },
    {
      question: "6. Provide services for airport transfers.",
      answer:
        "We do indeed specialize in airport transportation. Our chauffeurs follow your aircraft to guarantee on-time pick-ups; we also offer flawless, hassle-free transfers from the airport in comfort and style.",
    },
    {
      question: "7. Is it possible to personalize my trip route?",
      answer:
        "Yes! Specifying paths, stopping along the way, or changing the schedule can help you to personalize your travel. We will meet your needs only let the chauffeur know or forward our staff ahead of time.",
    },
    {
      question: "9. Should one pay for waiting time?",
      answer:
        "Depending on the service and location, we provide a free 30 to 60-minute waiting period. Beyond this, one can have a waiting time charge. We advise you to let us know your planned timetable so we may arrange appropriately.",
    },
    {
      question: "10. Which payment options are taken?",
      answer:
        "We accept credit cards, bank transfers, and mobile payments among other payment methods. Furthermore, our Use Globally, Pay Locally tool lets you pay in your native tongue even for international service bookings.",
    },
    {
      question:
        "11. What happens if my plans change? Is my booking cancellable or modifiable?",
      answer:
        "You can, indeed, change or cancel your booking. Plans vary, so we provide flexible choices to modify your travel. Different booking times and types affect cancellation policies; hence, kindly review our conditions or get help from our support.",
    },
    {
      question: "12. Are your cars insured?",
      answer:
        "Of course, every one of our vehicles has comprehensive insurance, including passenger responsibility, guaranteeing your safety and peace of mind throughout the trip.",
    },
    {
      question: "13. Are there any safety precautions you take?",
      answer:
        "Safety comes first for us. Our vehicles are routinely kept to the best of standards and serviced. Especially in view of health recommendations, our chauffeurs undertake rigorous hygienic and sanitation procedures; they also receive defensive driving training.",
    },
    {
      question:
        "14. Can I schedule Chauffeur Drive services for a whole day or a longer term?",
      answer:
        "We provide adaptable choices for Chauffeur Drive services both now and in the future. Depending on your needs, you might schedule a few hours, a full day, or even longer stretches.",
    },
    {
      question: "15. Do you offer chauffeur services outside of the city?",
      answer:
        "Yes, we provide out-of-town Chauffeur Drive services as well as intercity ones. Whether your trip is for business or enjoyment to a nearby city, our services will comfortably and securely get you there.",
    },
  ];

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <Annimations />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL + "/images/cia-chauffers-drive-banner.png"
          }
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Chauffeur Drive</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3   ">
              <h1 className=" fw-bold  cia-service-heading mb-3">
                Experience the luxury of convenience with Chauffeur Drive
                services{" "}
              </h1>

              <div className="underline mb-3 "></div>
              <p className="mb-4 fw-normal cia-service-discription">
                Whether for work, personal travel, or special events, our
                professional chauffeurs promise a hassle-free and enjoyable
                ride. Discover a range of high-end cars, including spacious SUVs
                and executive sedans that have been attentively kept to
                guarantee your comfort and safety. Sit back, unwind, and hire a
                chauffeur drive car. <br />
                <br />
                You will have the opportunity to concentrate on the most
                important things, while also receiving personalized service and
                punctuality through our Chauffeur Driven cars. Book your
                chauffeur-driven ride immediately and elevate your travel
                experience with Cars in Africa!
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>

            <div className="col-md-6 mt-3 zoom-out ">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-3.png"
                  }
                  alt="Large"
                  className="img-fluid px-1 "
                />
              </div>
              <div className="d-flex ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-5.png"
                  }
                  alt="Small 1"
                  className="img-fluid w-50 px-1 "
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-6.png"
                  }
                  alt="Small 2"
                  className="img-fluid w-50 px-1 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">
            Key Features
          </h2>
          <div className="row d-flex justify-content-center mx-2">
            <div className=" key-features-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row  ">
            {/* Card 1 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in ">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/online-app.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Online App</h5>
                <p className="why-choose-card-text">
                  Our app simplifies collaboration between Cars in Africa and
                  corporate clients, making the booking process easy and
                  efficient.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/customer-support2.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  24/7 Customer Support
                </h5>
                <p className="why-choose-card-text">
                  Cars in Africa prioritize your satisfaction with a dedicated
                  helpline, available around the clock to address any inquiries
                  or issues.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/professional-chauffeurs.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Professional Chauffeurs
                </h5>
                <p className="why-choose-card-text">
                  Ride with confidence! Our experienced, courteous drivers
                  ensure a smooth and safe journey, tailored to your
                  preferences.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/luxury-fleet.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Luxury Fleet</h5>
                <p className="why-choose-card-text">
                  From sleek executive sedans to spacious SUVs, our
                  top-of-the-line vehicles guarantee comfort and style for every
                  trip.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 5 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/punctual-and-reliable.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Punctual and Reliable{" "}
                </h5>
                <p className="why-choose-card-text">
                  Time is precious. Our commitment to on-time pick-ups and
                  drop-offs means you ll always stay on schedule, whether for
                  business or leisure.
                </p>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/customizable-routes.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Customizable Routes{" "}
                </h5>
                <p className="why-choose-card-text">
                  Your ride, your way. Flexibility to choose your preferred
                  routes, make multiple stops or plan scenic detours.
                </p>
              </div>
            </div>

            {/* Card 7 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in ">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/use-globally-pay-locally.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Use Globally, Pay Locally{" "}
                </h5>
                <p className="why-choose-card-text">
                  Access our chauffeur services across multiple cities and
                  countries, while enjoying the convenience of paying in your
                  local currency!
                </p>
              </div>
            </div>

            {/* Card 8 */}
            <div className="col-lg-3 col-md-6 mt-5  zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/business-event-travel.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Business & Event Travel{" "}
                </h5>
                <p className="why-choose-card-text">
                  Perfect for corporate travel or special events, offering a
                  sophisticated travel experience that reflects professionalism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 px-2   ">
        <div className="container shadow cia-chauffeur-drive-service-container ">
          <div className="row text-center slide-up">
            <h3 className=" fw-bold cia-why-choose-heading  mb-3">
              Airport Transfer{" "}
            </h3>

            <p className="mb-4 fw-semibold text-center">
              Seamless, stress-free airport transfers with on-time airport
              pickups, luxury <br /> vehicles, and professional drivers for
              comfortable travel.
            </p>
            <div className="cia-chauffeur-underline mb-3 m-auto"></div>
          </div>
          <div className="row">
            <div className="col-md-7 mt-3 d-flex align-items-center order-2 order-md-1">
              <p className="mb-4 fw-normal cia-service-discription ">
                Experience effortless and seamless airport car rental journeys
                using Cars in Africa. Whether you are arriving or leaving, our
                chauffeur-driven services provide on-time transfers, one-way car
                hire, and drop-offs, therefore enabling you to travel in luxury
                and elegance.
                <br /> <br />
                Experience peacefulness with timely arrival, expert chauffeurs,
                and beautifully kept cars. To fit all of your airport transfer
                needs, we provide a large selection of cars including premium
                SUVs and executive sedans.
              </p>
            </div>

            <div className="col-md-5 mt-3 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-4.png"
                  }
                  alt="Large"
                  className="img-fluid cia-transfer-image px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 px-2    ">
        <div className="container shadow cia-chauffeur-drive-service-container ">
          <div className="row text-center slide-up">
            <h3 className=" fw-bold  cia-why-choose-heading mb-3">
              Local / Intracity Services{" "}
            </h3>

            <p className="mb-4 fw-semibold text-center">
              Effortless city travel with chauffeur-driven or self-drive options
              tailored to your <br />
              daily needs, offering flexibility and competitive rates.
            </p>
            <div className="cia-chauffeur-underline mb-3 m-auto"></div>
          </div>
          <div className="row">
            <div className="col-md-7 mt-3 d-flex align-items-center order-2 order-md-2">
              <p className="mb-4 fw-normal cia-service-discription ">
                Explore the city effortlessly with Cars in Africa’s reliable
                local and intracity services. Whether for business meetings,
                errands, or city tours, our chauffeur-driven and self-drive
                options ensure comfort and convenience for your daily travel
                needs. Choose from a diverse fleet of well-maintained vehicles,
                including executive sedans and spacious SUVs, tailored to suit
                your preferences.
                <br /> <br />
                Enjoy the flexibility of on-demand bookings, competitive rates,
                and a smooth driving experience throughout the city. Make your
                intracity journey hassle-free with Cars in Africa!
              </p>
            </div>

            <div className="col-md-5 mt-3 order-1 order-md-1">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-5.png"
                  }
                  alt="Large"
                  className="img-fluid cia-transfer-image px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 px-2   ">
        <div className="container shadow cia-chauffeur-drive-service-container ">
          <div className="row text-center slide-up">
            <h3 className=" fw-bold cia-why-choose-heading mb-3">
              Intercity Services{" "}
            </h3>

            <p className="mb-4 fw-semibold text-center">
              Comfortable and reliable travel with luxury vehicles and
              professional drivers <br /> ensures safety and punctuality for
              business or leisure.
            </p>
            <div className="cia-chauffeur-underline mb-3 m-auto"></div>
          </div>
          <div className="row">
            <div className="col-md-7 mt-3 d-flex align-items-center order-2 order-md-1">
              <p className="mb-4 fw-normal cia-service-discription ">
                Travel between cities with ease and comfort using Cars in
                Africa’s premium intercity services. A seamless journey across
                regions is guaranteed with our chauffeur-driven cars whether for
                business travels or leisure getaways. You can enjoy
                long-distance travel with the utmost comfort and dependability,
                as our fleet spans from luxurious sedans to spacious SUVs.
                <br /> <br />
                Our intercity journeys are made smooth and worry-free by our
                professional drivers and well-maintained vehicles, which
                guarantee safety and punctuality. Book your intercity ride
                immediately with Cars in Africa and enjoy unparalleled service
                on the road!
              </p>
            </div>

            <div className="col-md-5 mt-3 order-1 order-md-2">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-6.png"
                  }
                  alt="Large"
                  className="img-fluid cia-transfer-image px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">
            Ride in Style with Cars in Africa’s Chauffeur-Drive Services!
          </h2>
          <p className="mb-4 fw-normal text-center">
            Ready to elevate your travel game? Whether it’s for business, a
            special event, or just to enjoy the journey, Cars in Africa’s
            Chauffeur-Drive
            <br />
            services bring you the luxury of convenience like never before!
          </p>

          <div className="row d-flex justify-content-center mx-2">
            <div className="cia-chauffeur-ride-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row  ">
            {/* Card 1 */}
            <div className="col-lg-4 col-md-6 mt-5 zoom-out ">
              <div className="card h-100  p-3 d-flex align-items-left justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/professional-chauffeurs1.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Professional Chauffeurs
                </h5>
                <p className="why-choose-card-text">
                  Courteous, skilled drivers for a smooth, stress-free ride.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-4 col-md-6 mt-5 zoom-out ">
              <div className="card h-100  p-3 d-flex align-items-left justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/premium-cars.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Premium Cars</h5>
                <p className="why-choose-card-text">
                  From sleek executive sedans to spacious SUVs, we've got you
                  covered.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-4 col-md-6 mt-5 zoom-out ">
              <div className="card h-100  p-3 d-flex align-items-left justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/on-time-every-time.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">On-Time, Every Time</h5>
                <p className="why-choose-card-text">
                  Punctual service so you can focus on what matters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Want To Travel In Comfort Without Driving?"
        buttonText="Book Chauffeur Drive Now"
        redirection="/"
      />

      <Faq3 faqs={faqs} title="FAQ's" />
      <Footer />
    </>
  );
}
