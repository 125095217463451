import React, { useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import "slick-carousel/slick/slick.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import SearchForm from "../../components/Search-form/Searchform.js";
// import { Link } from "react-router-dom";
// import { TinySlider } from "react";
import "./Homepage.css";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import axios from "axios";
import CountUp from "react-countup";
import ChooseUsSlider from "../../components/WhyChooseUs/ChooseUsSlider.js";
import DiscoverFeatures from "../../components/Home/DiscoverFeatures.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";

export default function Homepage() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [ourValues, setOurValues] = useState([
    {
      serving_countries: 0,
      locations: 0,
      happy_miles: 0,
      vehicle_inventories: 0,
      happy_miles_million: 0,
      vehicle_inventories_thousand: 0,
    },
  ]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const SliderComponent = () => {
    useEffect(() => {
      $(document).ready(() => {
        $(".slider-items-active").slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true,
        });
      });
      // Cleanup
      return () => {
        $(".slider-items-active").slick("unslick");
      };
    }, []);
  };

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("home");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    axios
      .get("https://backend.carsinafrica.com/frontend/vehiclescount")
      .then(function (response) {
        setOurValues(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <>
    <Annimations />
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      {/* Home page -> START */}
      <Header />
      <div>
        <SearchForm />
      </div>
      <section className="vehicle-types-section vehicle-section-bg pt-5">
        <div className="container full-width hp-fleet-vehicle">
          <div className="title-sections text-center">
            <h1 className="title-sections__title fw-bold">
              Our Vehicles Fleet
            </h1>
            <p className="title-sections__tagline text-muted">
              Discover Our Impressive Fleet Vehicle Transform your dreams into
              reality with our exceptional selection of versatile vehicles
            </p>
            <hr />
          </div>
          <div
            id="vehicleCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-3 slide-up">
                    <div className="card mb-4 custom-cursor">
                      <div className="card-body mb-2">
                        <h3 className="card-title font-weight-bold">Sedan</h3>
                        <p className="card-text text-muted">
                          Discover our models of the electric, hybrid, or
                          plug-in vehicles.
                        </p>
                        <div className="vehicle-card__image-link">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/Black-Seden-cia.png"
                            }
                            className="img-fluid "
                            alt="Sedan"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 slide-up">
                    <div className="card mb-4 custom-cursor">
                      <div className="card-body">
                        <h3 className="card-title font-weight-bold">SUV</h3>
                        <p className="card-text text-muted">
                          You can choose from a wide range of luxury vehicles
                          made by legendary.
                        </p>
                        <div className="vehicle-card__image-link text-start">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/SUV-cia.png"
                            }
                            className="img-fluid custom-margin-top-negative-1"
                            alt="SUV"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 slide-up">
                    <div className="card mb-4 custom-cursor">
                      <div className="card-body">
                        <h3 className="card-title font-weight-bold">
                          Premium Car
                        </h3>
                        <p className="card-text text-muted">
                          These range from compact and fuel-efficient city to
                          eco-friendly models.
                        </p>
                        <div className="vehicle-card__image-link">
                          <img
                            src={process.env.PUBLIC_URL + "/images/Premium-Car-cia.png"}
                            className="img-fluid  "
                            alt="Preminum car"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 slide-up">
                    <div className="card mb-4 custom-cursor">
                      <div className="card-body">
                        <h3 className="card-title font-weight-bold">Minivan</h3>
                        <p className="card-text text-muted">
                          You’re looking for a minivan for your business or
                          leisure trip?
                        </p>
                        <div className="vehicle-card__image-link">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/Mini-van-cia.png"
                            }
                            className="img-fluid custom-margin-top-negative-1"
                            alt="minivan"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="mb-5 hp-why-choose-us pt-5">
        <div
          className="container full-width pt-5 mb-5 why-choose-us"
          id="featured-3"
        >
          <h1 className="pb-2 fw-bold text-center">Why Choose Us...</h1>
          <p className="border-bottom pb-2 text-center">
            In the market of car rental services, We provide you with various
            best services which would make you choose us.
          </p>
          <div className="row g-4">
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-10 rounded-circle mb-4">
                  <i className="bi bi-lightning-fill fs-5" />
                </div>
                <h5 className="pb-2">Availability of customizable services</h5>
                <p className="mb-0">
                  The customers have the option for tailor-made services
                  according to their specific needs. For example, in the context
                  of a car rental service, a customer might want to have
                  additional amenities like child seats to their rental.{" "}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-10 text-success rounded-circle mb-4">
                  <i className="bi bi-bookmark-check-fill fs-5" />
                </div>
                <h5 className="pb-2">Flexible booking plans</h5>
                <p className="mb-0">
                  It enables customers to book services on a schedule that suits
                  them. For example, a car rental service might offer customers
                  the option to book hourly, daily, weekly, or monthly rentals,
                  allowing them to choose the booking plan that best fits their
                  needs.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-15 text-warning rounded-circle mb-4">
                  <i className="bi bi-currency-exchange fs-5" />
                </div>
                <h5 className="pb-2">Negotiated and real-time costs</h5>
                <p className="mb-0">
                  We provide the ability to negotiate costs and receive
                  real-time pricing information for the services they are
                  interested in. In the context of a car rental service, a
                  customer might be able to negotiate a better price based on
                  the duration of their rental or other factors.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-10 text-danger rounded-circle mb-4">
                  <i className="bi bi-credit-card-fill fs-5" />
                </div>
                <h5 className="pb-2">
                  Quick, secure & multiple modes of payments
                </h5>
                <p className="mb-0">
                  We offer the ability to pay for services through a variety of
                  payment options such as credit cards, debit cards, PayPal, or
                  other payment gateways. Customers should be able to pay
                  quickly and securely, without any concerns about the safety of
                  their payment information.{" "}
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-10 text-orange rounded-circle mb-4">
                  <i className="bi bi-coin fs-5" />
                </div>
                <h5 className="pb-2">Currency flexibility</h5>
                <p className="mb-0">
                  Customers can pay for services in their preferred currency,
                  regardless of their location or the location of the service
                  provider. This feature is especially important for
                  international services where customers may be from different
                  countries with different currencies.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100 bg-cards">
                <div className="icon-lg bg-opacity-10 text-info rounded-circle mb-4">
                  <i className="bi bi-people-fill fs-5" />
                </div>
                <h5 className="pb-2">Grouping & tracking</h5>
                <p className="mb-0">
                  It allows customers to track and manage their services in a
                  more organized manner. For instance, a car rental service
                  might allow customers to group their rentals by trip or
                  purpose, making it easier for them to manage multiple rentals
                  at once.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-5"></div>
        </div>
      </section> */}

      {/* START -> New section for why choose us */}

      <DiscoverFeatures />

      {/* END -> New section for why choose us */}

      {/* START -> New section for why choose us Slider */}
      <ChooseUsSlider />
      {/* END -> New section for why choose us Slider */}

      <section className="home-testimonial">
        <div className="container-fluid">
          <div className="pricing-header p-3 pb-md-2 mx-auto text-center position-relative">
            <h1 className="display-5 fw-bold text-black">Testimonials</h1>
          </div>
          <div className="row d-flex justify-content-center testimonial-pos">
            <div className="col-md-12 d-flex justify-content-center text-black">
              <h2 className="text-black">
                What People Are Saying About Cars In Africa
              </h2>
            </div>
          </div>
          <div className="container-fluid py-5" id="mobile-block">
            <div className="row">
              <div className="col-md-12 col-lg-12 mx-auto">
                <div className="lc-block py-4 text-center">
                  <div
                    id="carouselTestimonial"
                    className="carousel slide pb-4"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “My South Africa trip was amazing with Cars In
                                Africa! Elegant car, excellent chauffeur
                                service. Highly recommend for hassle-free
                                travel"
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-3.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Mark Thompson
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “Whether business or pleasure, Cars In Africa
                                never disappoints. Stress-free commute with
                                their reliable employee transportation. I'll
                                book again!"
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-1.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- John Smith</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “I had a fantastic time hiring a car from the
                                CIA. The procedure was quick and
                                straightforward, and the personnel was courteous
                                and knowledgeable."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar02jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Harris Johnson
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-justify mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-justify">
                                “Thank you very much for everything! I hope to
                                hire a car with your amazing service! I am very
                                happy with the service via email, and corporate
                                meetings."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar03jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- Durso Raeen</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “I trust Cars In Africa for all my travel needs.
                                Their commitment to satisfaction ensures a safe
                                and comfortable journey every time."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-2.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Sarah Onyango
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “This company was the able to provide me with a
                                car within the hour and the staff was extremely
                                accommodating and made the process stress-free."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar01jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- James Raabin</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 text-center mt-3">
                      <a
                        className="carousel-control-prev position-relative d-inline me-4"
                        href="#carouselTestimonial"
                        data-bs-slide="prev"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="text-dark"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                        <span className="visually-hidden">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next position-relative d-inline"
                        href="#carouselTestimonial"
                        data-bs-slide="next"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="text-dark"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="visually-hidden">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* /col */}
            </div>
          </div>

          {/* Mobile Slider */}
          <div className="row" id="mobile-block2">
            <div className="col-md-12 col-lg-12 mx-auto">
              <div className="lc-block py-4 text-center">
                <div
                  id="carouselTestimonial"
                  className="carousel slide pb-4"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “My South Africa trip was amazing with Cars In
                              Africa! Elegant car, excellent chauffeur service.
                              Highly recommend for hassle-free travel"<br></br>
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-3.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Mark Thompson</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “Whether business or pleasure, Cars In Africa
                              never disappoints. Stress-free commute with their
                              reliable employee transportation. I'll book
                              again!"
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-1.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- John Smith</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “I had a fantastic time hiring a car from the CIA.
                              The procedure was quick and straightforward, and
                              the personnel was courteous and knowledgeable."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar02jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Harris Johnson</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “Thank you very much for everything! I hope to
                              hire a car with your amazing service! I am very
                              happy with the service via email, and corporate
                              meetings."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar03jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Durso Raeen</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “I trust Cars In Africa for all my travel needs.
                              Their commitment to satisfaction ensures a safe
                              and comfortable journey every time."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-2.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Sarah Onyango</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “This company was the able to provide me with a
                              car within the hour and the staff was extremely
                              accommodating and made the process stress-free."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar01jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- James Raabin</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 text-center mt-3">
                    <a
                      className="carousel-control-prev position-relative d-inline me-4"
                      href="#carouselTestimonial"
                      data-bs-slide="prev"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="text-dark"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* Previous icon */}
                      </svg>
                      <span className="visually-hidden">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next position-relative d-inline"
                      href="#carouselTestimonial"
                      data-bs-slide="next"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="text-dark"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* Next icon */}
                      </svg>
                      <span className="visually-hidden">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="pricing-header p-5 pb-md-4 text-center mb-our-values">
        <h1 className="mb-0 fw-bold lh-lg">Our Values</h1>
        <p className="border-bottom pb-2">
          Driving your dreams to reality with an exquisite fleet of versatile
          vehicles for unforgettable journeys.
        </p>
      </div>

      <div className="rn-section rn-about-counter custom-background-color">
        <div className="rn-section-overlayer pt-4" />
        <div className="container full-width">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="rn-counter-item">
                <div className="rn-counter-number-container">
                  <CountUp
                    className="rn-counter-number"
                    end={ourValues[0].serving_countries}
                  />
                  <span className="rn-counter-postfix">+</span>
                </div>
                <div className="rn-counter-text">Serving Countries</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rn-counter-item">
                <div className="rn-counter-number-container">
                  <CountUp
                    className="rn-counter-number"
                    end={ourValues[0].locations}
                  />
                  <span className="rn-counter-postfix">+</span>
                </div>
                <div className="rn-counter-text">Locations</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rn-counter-item">
                <div className="rn-counter-number-container">
                  <CountUp
                    className="rn-counter-number"
                    end={ourValues[0].happy_miles}
                  />
                  <span className="rn-counter-postfix">+</span>
                </div>
                <div className="rn-counter-text">Happy Miles</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rn-counter-item">
                <div className="rn-counter-number-container">
                  <CountUp
                    className="rn-counter-number"
                    end={ourValues[0].vehicle_inventories}
                  />
                  <span className="rn-counter-postfix">+</span>
                </div>
                <div className="rn-counter-text">Vehicle Inventories</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {/* Home page -> END */}
    </>
  );
}
