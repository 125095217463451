import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { getToken } from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import "reactjs-popup/dist/index.css";
import "./Carlist.css";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getCarListingData } from "../../services/Apis/CarsInAfrica/OtherApis/carListApi";
import savaarisourcecities from "../../services/Apis/Savaari/Json/source-cities.json";
import savaaridestinationcities from "../../services/Apis/Savaari/Json/destination-cities.json";

export default function Carlist() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [category, setCategory] = useState("All");
  const [sort, setSort] = useState("lowtohigh");
  const [vehicleAllCategories, setVehicleAllCategories] = useState([]);

  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [vehicleResultFlag, setVehicleResultFlag] = useState(false);

  const [vehicleResults, setVehicleResults] = useState([]);

  const [savaariApiToken, setSavaariApiToken] = useState(null);

  const [vehicleListPage, setVehicleListPage] = useState(1);

  const itemsPerPage = 6; // Number of items to display per page
  const userLoggedData = getToken();

  const savaariServiceTax = 1.1;

  const getCarListingUrl =
    "https://backend.carsinafrica.com/frontend/getCarListing";

  const {
    service,
    subservice,
    triptype,
    paddress,
    pcity,
    pstate,
    pcountryiso,
    pcountry,
    platcoordinates,
    plngcoordinates,
    daddress,
    dcity,
    dstate,
    dcountryiso,
    dlatcoordinates,
    dlngcoordinates,
    country,
    countryiso,
    pdate,
    ptime,
    ddate,
    dtime,
    userid,
    userAge,
  } = useParams();

  const vehicleSearchRequestData = {
    service: service,
    sub_service: subservice,
    pickup_address: paddress,
    pickup_city: pcity,
    pickup_state: pstate,
    pickup_country_iso: pcountryiso,
    dropoff_address: daddress,
    dropoff_city: dcity,
    dropoff_state: dstate,
    dropoff_country_iso: dcountryiso,
    pick_date: pdate,
    pick_time: ptime,
    drop_date: ddate,
    drop_time: dtime,
    user_id: userid,
  };

  const vehicleJsonSearchRequestData = JSON.stringify(vehicleSearchRequestData);

  const [savaariVehicles, setSavaariVehicles] = useState([]);

  const [savaariSource, setSavaariSource] = useState({});
  const [savaariDestination, setSavaariDestination] = useState({});

  const [savaariVehicleService, setSavaariVehicleService] = useState(null);
  const [savaariVehicleSubService, setSavaariVehicleSubService] =
    useState(null);

  const [savaariVehicleLoading, setSavaariVehicleLoading] = useState(true);
  const [savaaariVehicleResultFlag, setSavaariVehicleResultFlag] =
    useState(false);

  const [iWayToken, setIWayToken] = useState(null);
  const [iWayVehicles, setIWayVehicles] = useState([]);

  const [getTransferToken, setGetTransferToken] = useState(null);
  const [getTransferVehicles, setGetTransferVehicles] = useState([]);

  const [vehicleListLoader, setVehicleListLoader] = useState(true);
  const [vehicleListFlagger, setVehicleListFlagger] = useState(false);
  const [vehicleListProvider, setVehicleListProvider] = useState(null);

  function formatIWayDate(date) {
    const [dd, mm, yyyy] = date.split("-");

    const formattedDate = `${yyyy}-${mm}-${dd}`;

    const iWayDateTime = `${formattedDate}`;

    return iWayDateTime;
  }

  const iWayPickupDate = formatIWayDate(pdate);

  const noOfDaysDuration = 1;

  const decimalsToRound = (num, decimalPlaces = 2) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  };
  // START -> Getting Meta Tags
  useLayoutEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=vehicle-results&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);
  // END -> Getting Meta Tags

  // Define the headers
  const headers = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        if (pcountryiso === "IN") {
          const savaariResponse = await SavaariVehicleListCall();
          if (savaariResponse) {
            setVehicleListFlagger(true);
            setVehicleListLoader(false);
            setVehicleListProvider("savaari");
          } else {
            const ciaResponse = await CiaVehicleListCall();
            if (ciaResponse) {
              setVehicleListFlagger(true);
              setVehicleListLoader(false);
              setVehicleListProvider("cars-in-africa");
            } else {
              setVehicleListFlagger(false);
              setVehicleListLoader(false);
            }
          }
        } else {
          const ciaResponse = await CiaVehicleListCall();
          if (ciaResponse) {
            setVehicleListFlagger(true);
            setVehicleListLoader(false);
            setVehicleListProvider("cars-in-africa");
          } else {
            setVehicleListFlagger(false);
            setVehicleListLoader(false);
          }
        }
      } catch (error) {
        setVehicleListFlagger(false);
        setVehicleListLoader(false);
      }
    };

    fetchVehicleData();
  }, []);

  // START -> Vehicle List call Controller
  async function SavaariVehicleListCall() {
    const cityIdsSet = await setSavaariCityIds();

    const vehicleServicesSet = await setSavaariVehicleServices();

    if (cityIdsSet.status && vehicleServicesSet.status) {
      const savaariApiToken = await getSavaariToken();

      if (savaariApiToken.status) {
        const responseData = await getSavaariVehicles(
          cityIdsSet,
          vehicleServicesSet,
          savaariApiToken
        );
        if (responseData.status) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async function CiaVehicleListCall() {
    getCarListingData(
      service,
      subservice,
      triptype,
      paddress,
      pcity,
      pstate,
      platcoordinates,
      plngcoordinates,
      pdate,
      ptime,
      daddress,
      dcity,
      dstate,
      dlatcoordinates,
      dlngcoordinates,
      ddate,
      dtime,
      country,
      countryiso,
      userid,
      userAge
    );
    try {
      const response = await axios.post(
        getCarListingUrl,
        vehicleJsonSearchRequestData,
        {
          headers: headers,
        }
      );
      // Handle the response here
      if (response.data.status === "failed") {
        return false;
      } else {
        setVehicleResults(response.data.data);
        const categories = new Set(
          response.data.data.map((vehicle) => vehicle.vehicletype)
        );
        setVehicleAllCategories([...categories]);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async function IWayVehicleListCall() {
    const responseData = await getIWayVehicles();
    if (responseData) {
      return true;
    } else {
      return false;
    }
  }

  async function GetTransferVehicleListCall() {
    const getTransferApiToken = await getGetTransferToken();
    if (getTransferApiToken.status) {
      const responseData = await getGetTransferVehicles(getTransferApiToken);
      if (responseData) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // END -> Vehicle List call Controller

  // START -> Filtering Vehicles For Cars In Africa

  function handleCategoryChange(event) {
    setCategory(event.target.value);
  }

  function handleSortChange(event) {
    setSort(event.target.value);
  }

  function filterVehicles() {
    // const categories = new Set(
    //   vehicleResults.map((vehicle) => vehicle.vehicletype)
    // );
    // setVehicleAllCategories([...categories]);
    let filteredVehicles = vehicleResults.filter((vehicleResults) => {
      if (category === "All") {
        return true;
      } else {
        return vehicleResults.vehicletype === category;
      }
    });

    switch (sort) {
      case "lowtohigh":
        filteredVehicles.sort((a, b) => a.base_fare - b.base_fare);
        break;
      case "hightolow":
        filteredVehicles.sort((a, b) => b.base_fare - a.base_fare);
        break;
      case "atoz":
        filteredVehicles.sort((a, b) => a.make.localeCompare(b.make));
        break;
      case "ztoa":
        filteredVehicles.sort((a, b) => b.make.localeCompare(a.make));
        break;
      default:
        break;
    }
    return filteredVehicles;
  }

  const filteredVehicles = filterVehicles();

  // END -> Filtering Vehicles For Cars In Africa

  // START -> Pagonation For For CIA Car List
  const handlePageVehicleListChange = (newPage) => {
    setVehicleListPage(newPage);
  };
  const startVehicleListIndex = (vehicleListPage - 1) * itemsPerPage;
  const endVehicleListIndex = startVehicleListIndex + itemsPerPage;
  // END -> Pagonation For Cia Car List.
  // END -> Cars In Africa Vehicles Implementation

  // START -> Savaari Vehicles Implementation
  //  Savaari Token Generator
  async function getSavaariToken() {
    try {
      const response = await axios.get(
        `https://backend.carsinafrica.com/savaari_api/get_savaari_token.php`
      );
      const token = response.data.data.token;
      setSavaariApiToken(token);
      return { token, status: true };
    } catch (error) {
      console.error("Error fetching token:", error);
      return { token: null, status: false };
    }
  }

  // START -> Savaari finding source & destination cities Id
  async function setSavaariCityIds() {
    const sourceCity = savaarisourcecities.find(
      (tpcity) => tpcity.org === pcity
    );
    const destinationCity = savaaridestinationcities.find(
      (tdcity) => tdcity.org === dcity
    );

    setSavaariSource(sourceCity);
    setSavaariDestination(destinationCity);

    if (sourceCity && destinationCity) {
      const serviceResponse = {
        sourceCityId: sourceCity,
        destinationCityId: destinationCity,
        status: true,
      };
      return serviceResponse;
    } else {
      const serviceResponse = {
        sourceCityId: null,
        destinationCityId: null,
        status: false,
      };
      return serviceResponse;
    }
  }
  // END -> Savaari finding source & destination cities Id

  // START -> Savaari Service Provider
  async function setSavaariVehicleServices() {
    if (service === "local") {
      if (subservice === "halfday") {
        setSavaariVehicleService("local");
        setSavaariVehicleSubService("880");
        const serviceResponse = {
          service: "local",
          subService: "880",
          status: true,
        };
        return serviceResponse;
      } else if (subservice === "fullday") {
        setSavaariVehicleService("local");
        setSavaariVehicleSubService("12120");
        const serviceResponse = {
          service: "local",
          subService: "12120",
          status: true,
        };
        return serviceResponse;
      }
    } else if (service === "outstation") {
      setSavaariVehicleService("outstation");
      setSavaariVehicleSubService("oneway");
      const serviceResponse = {
        service: "outstation",
        subService: "oneway",
        status: true,
      };
      return serviceResponse;
    } else if (service === "transfer") {
      if (subservice === "pick-airport") {
        setSavaariVehicleService("airport");
        setSavaariVehicleSubService("pick_airport");
        const serviceResponse = {
          service: "airport",
          subService: "pick_airport",
          status: true,
        };
        return serviceResponse;
      } else if (subservice === "drop-airport") {
        setSavaariVehicleService("airport");
        setSavaariVehicleSubService("drop_airport");
        const serviceResponse = {
          service: "airport",
          subService: "drop_airport",
          status: true,
        };
        return serviceResponse;
      }
    } else if (service === "selfdrive") {
      setSavaariVehicleService("selfdrive");
      setSavaariVehicleSubService("selfdrive");
      const serviceResponse = {
        service: "selfdrive",
        subService: "selfdrive",
        status: true,
      };
      return serviceResponse;
    }
    const serviceResponse = { service: null, subService: null, status: false };
    return serviceResponse;
  }
  // END -> Savaari Service Provider

  // START -> Savaari Get Vehicle Listing
  async function getSavaariVehicles(
    cityIdsSet,
    vehicleServicesSet,
    savaariApiToken
  ) {
    const savaariErrorData = [
      // Add more error objects as needed
      { id: 1, message: "Error occurred: Vehicle not found", carId: null },
    ];
    if (vehicleServicesSet.service === "local") {
      // START - Local -> 440 savaari vehicle GET
      if (cityIdsSet.sourceCityId) {
        try {
          const response = await axios.get(
            `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken.token}&sourceCity=${cityIdsSet.sourceCityId.cityId}&tripType=${vehicleServicesSet.service}&subTripType=${vehicleServicesSet.subService}&pickupDateTime=${pdate}%20${ptime}&duration=${noOfDaysDuration}&customerLatLong=${platcoordinates},${plngcoordinates}`
          );
          if (response.data.status === "success") {
            setSavaariVehicles(response.data.data.availableCars);
            setSavaariVehicleResultFlag(true);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            const vehicleResponse = {
              data: response.data.data.availableCars,
              status: true,
            };
            return vehicleResponse;
          } else {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            setSavaariVehicles(savaariErrorData);
            const vehicleResponse = {
              data: null,
              status: false,
            };
            return vehicleResponse;
          }
        } catch (error) {
          setSavaariVehicleResultFlag(false);
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
          console.error(error.message);
          setSavaariVehicles(savaariErrorData);
          const vehicleResponse = {
            data: null,
            status: false,
          };
          return vehicleResponse;
        }
      } else {
        setSavaariVehicleResultFlag(false);
        setSavaariVehicleLoading(false);
        setVehicleLoading(false);
        console.error("Pickup City Is Invalid");
        setSavaariVehicles(savaariErrorData);
        const vehicleResponse = {
          data: null,
          status: false,
        };
        return vehicleResponse;
      }
      // END - Local -> 440 savaari vehicle GET
    } else if (vehicleServicesSet.service === "outstation") {
      if (vehicleServicesSet.subService === "oneway") {
        // START - outstation -> oneway savaari vehicle GET
        if (cityIdsSet.sourceCityId) {
          if (cityIdsSet.destinationCityId) {
            try {
              const response = await axios.get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken.token}&sourceCity=${cityIdsSet.sourceCityId.cityId}&tripType=${vehicleServicesSet.service}&subTripType=${vehicleServicesSet.subService}&pickupDateTime=${pdate}%20${ptime}&duration=1&destinationCity=${cityIdsSet.destinationCityId.cityId}&customerLatLong=${platcoordinates},${plngcoordinates}`
              );

              if (response.data.status === "success") {
                setSavaariVehicles(response.data.data.availableCars);
                setSavaariVehicleResultFlag(true);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                const vehicleResponse = {
                  data: response.data.data.availableCars,
                  status: true,
                };
                return vehicleResponse;
              } else {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                setSavaariVehicles(savaariErrorData);
                const vehicleResponse = {
                  data: null,
                  status: false,
                };
                return vehicleResponse;
              }
            } catch (error) {
              setSavaariVehicleResultFlag(false);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
              setSavaariVehicles(savaariErrorData);
              console.error(error.message);
              const vehicleResponse = {
                data: null,
                status: false,
              };
              return vehicleResponse;
            }
          } else {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            setSavaariVehicles(savaariErrorData);
            console.error("Drop City not found!");
            const vehicleResponse = {
              data: null,
              status: false,
            };
            return vehicleResponse;
          }
        } else {
          setSavaariVehicleResultFlag(false);
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
          setSavaariVehicles(savaariErrorData);
          console.error("Pickup City not found!");
          const vehicleResponse = {
            data: null,
            status: false,
          };
          return vehicleResponse;
        }
        // END - outstation -> oneway savaari vehicle GET
      } else if (vehicleServicesSet.subService === "roundtrip") {
        // START - outstation -> roundtrip savaari vehicle GET
        if (cityIdsSet.sourceCityId) {
          if (cityIdsSet.destinationCityId) {
            try {
              const response = await axios.get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken.token}&sourceCity=${cityIdsSet.sourceCityId.cityId}&tripType=${vehicleServicesSet.service}&subTripType=${vehicleServicesSet.subService}&pickupDateTime=${pdate}%20${ptime}&duration=${noOfDaysDuration}&destinationCity=${cityIdsSet.destinationCityId.cityId}&customerLatLong=${platcoordinates},${plngcoordinates}`
              );
              if (response.data.status === "success") {
                setSavaariVehicles(response.data.data.availableCars);
                setSavaariVehicleResultFlag(true);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                const vehicleResponse = {
                  data: response.data.data.availableCars,
                  status: true,
                };
                return vehicleResponse;
              } else {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                setSavaariVehicles(savaariErrorData);
                const vehicleResponse = {
                  data: null,
                  status: false,
                };
                return vehicleResponse;
              }
            } catch (error) {
              setSavaariVehicleResultFlag(false);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
              setSavaariVehicles(savaariErrorData);
              console.error(error.message);
              const vehicleResponse = {
                data: null,
                status: false,
              };
              return vehicleResponse;
            }
          } else {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            setSavaariVehicles(savaariErrorData);
            console.error("Drop City not found!");
            const vehicleResponse = {
              data: null,
              status: false,
            };
            return vehicleResponse;
          }
        } else {
          setSavaariVehicleResultFlag(false);
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
          setSavaariVehicles(savaariErrorData);
          console.error("Pickup City not found!");
          const vehicleResponse = {
            data: null,
            status: false,
          };
          return vehicleResponse;
        }
        // END - outstation -> roundtrip savaari vehicle GET
      }
    } else if (vehicleServicesSet.service === "airport") {
      if (vehicleServicesSet.subService === "pick_airport") {
        // START - transfer -> Pick Airport savaari vehicle GET
        if (cityIdsSet.sourceCityId) {
          if (cityIdsSet.destinationCityId) {
            try {
              const response = await axios.get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken.token}&sourceCity=${cityIdsSet.sourceCityId.cityId}&destinationCity=${cityIdsSet.destinationCityId.cityId}&tripType=${vehicleServicesSet.service}&subTripType=${vehicleServicesSet.subService}&pickupDateTime=${pdate}%20${ptime}&duration=1&customerLatLong=${platcoordinates},${plngcoordinates}`
              );
              if (response.data.status === "success") {
                setSavaariVehicles(response.data.data.availableCars);
                setSavaariVehicleResultFlag(true);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                const vehicleResponse = {
                  data: response.data.data.availableCars,
                  status: true,
                };
                return vehicleResponse;
              } else {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                setSavaariVehicles(savaariErrorData);
                const vehicleResponse = {
                  data: null,
                  status: false,
                };
                return vehicleResponse;
              }
            } catch (error) {
              setSavaariVehicleResultFlag(false);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
              setSavaariVehicles(savaariErrorData);
              console.error(error.message);
              const vehicleResponse = {
                data: null,
                status: false,
              };
              return vehicleResponse;
            }
          } else {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            setSavaariVehicles(savaariErrorData);
            console.error("Drop City not found!");
            const vehicleResponse = {
              data: null,
              status: false,
            };
            return vehicleResponse;
          }
        } else {
          setSavaariVehicleResultFlag(false);
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
          setSavaariVehicles(savaariErrorData);
          console.error("Pickup City not found!");
          const vehicleResponse = {
            data: null,
            status: false,
          };
          return vehicleResponse;
        }
        // END - transfer -> Pick Airport savaari vehicle GET
      } else if (vehicleServicesSet.subService === "drop_airport") {
        // START - transfer -> Drop Airport savaari vehicle GET
        if (cityIdsSet.sourceCityId) {
          if (cityIdsSet.destinationCityId) {
            try {
              const response = await axios.get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken.token}&sourceCity=${cityIdsSet.sourceCityId.cityId}&destinationCity=${cityIdsSet.destinationCityId.cityId}&tripType=${vehicleServicesSet.service}&subTripType=${vehicleServicesSet.subService}&pickupDateTime=${pdate}%20${ptime}&duration=1&customerLatLong=${platcoordinates},${plngcoordinates}`
              );
              if (response.data.status === "success") {
                setSavaariVehicles(response.data.data.availableCars);
                setSavaariVehicleResultFlag(true);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                const vehicleResponse = {
                  data: response.data.data.availableCars,
                  status: true,
                };
                return vehicleResponse;
              } else {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                setSavaariVehicles(savaariErrorData);
                const vehicleResponse = {
                  data: null,
                  status: false,
                };
                return vehicleResponse;
              }
            } catch (error) {
              setSavaariVehicleResultFlag(false);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
              setSavaariVehicles(savaariErrorData);
              console.error(error.message);
              const vehicleResponse = {
                data: null,
                status: false,
              };
              return vehicleResponse;
            }
          } else {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            setSavaariVehicles(savaariErrorData);
            console.error("Drop City not found!");
            const vehicleResponse = {
              data: null,
              status: false,
            };
            return vehicleResponse;
          }
        } else {
          setSavaariVehicleResultFlag(false);
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
          setSavaariVehicles(savaariErrorData);
          console.error("Pickup City not found!");
          const vehicleResponse = {
            data: null,
            status: false,
          };
          return vehicleResponse;
        }
        // END - transfer -> Pick Airport savaari vehicle GET
      }
    } else if (vehicleServicesSet.service === "selfdrive") {
      setSavaariVehicleResultFlag(false);
      setSavaariVehicleLoading(false);
      setVehicleLoading(false);
      setSavaariVehicles(savaariErrorData);
      const vehicleResponse = {
        data: null,
        status: false,
      };
      return vehicleResponse;
    } else {
      const vehicleResponse = {
        data: null,
        status: false,
      };
      return vehicleResponse;
    }
  }
  // END -> Savaari Get Vehicle Listing
  // END -> Savaari Vehicles Implementation

  // START -> IWay Implementation
  // START -> Iway Get Vehicle Listing
  async function getIWayVehicles() {
    try {
      const iway_vehicle_list_response = await axios.get(
        `https://carsinafrica.com/iway_api/get_vehicle_list.php?iway_service=${service}&p_lat_coordinate=${platcoordinates}&p_lng_coordinate=${plngcoordinates}&d_lat_coordinate=${dlatcoordinates}&d_lng_coordinate=${dlngcoordinates}&p_date=${iWayPickupDate}`
      );
      if (iway_vehicle_list_response.data.status === "success") {
        setIWayVehicles(iway_vehicle_list_response.data.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  // END -> Iway Get Vehicle Listing
  // END -> IWay Implementation

  // START -> get Transfer Implementation
  // START -> get transfer Token
  async function getGetTransferToken() {
    try {
      const response = await axios.get(
        "https://carsinafrica.com/get-transfer/generate_token.php"
      );

      const token = response.data.data;
      setGetTransferToken(token);
      return { token, status: true };
    } catch (error) {
      console.error("Error fetching token:", error);
      return { token: null, status: false };
    }
  }
  // END -> get transfer Get Token

  // START -> Get Transfer Get Vehicle Listing
  async function getGetTransferVehicles(getTransferApiToken) {
    const headers = { Authorization: `Bearer ${getTransferApiToken.token}` };

    try {
      const response = await axios.get(
        `https://gtrbox.org/api/route_info?points%5B%5D=%2851.4679914%2C-0.455051%29&points%5B%5D=%2851.5123443%2C-0.0909852%29&countries%5B%5D=GB&with_prices=true&pax=2&date_to=2023-11-15T18%3A30%3A00%2B07%3A00&currency=USD&distance_unit=km`,
        { headers }
      );
      if (response.data.status !== "OK") {
        return false;
      } else {
        const pricesArray = Object.entries(response.data.prices).map(
          ([type, details]) => ({
            type,
            ...details,
          })
        );
        // console.log(pricesArray);
        // const filteredResult = response.data.result.filter(
        //   (item) => !item.blackout_date.includes(iWayPickupDate)
        // );
        // if (filteredResult.length === 0) {
        //   return false;
        // } else {
        //   setIWayVehicles(filteredResult);
        //   return true;
        // }
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  // END -> Get Transfer Get Vehicle Listing
  // END -> get Transfer Implementation

  // START -> loading GIF for vehicle Is loading Page
  if (
    vehicleListProvider === null &&
    vehicleListFlagger === false &&
    vehicleListLoader === true
  ) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={
              process.env.PUBLIC_URL + "/images/back-shot-white-car-list.jpg"
            }
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Available Cars</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> loading GIF for vehicle Is loading Page

  // START -> vehicle Listing Page
  else if (
    vehicleListProvider === "cars-in-africa" &&
    vehicleListFlagger === true &&
    vehicleListLoader === false
  ) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={
              process.env.PUBLIC_URL + "/images/back-shot-white-car-list.jpg"
            }
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Available Cars</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="container full-width mt-4">
          <div className="row d-flex">
            <div className="col-md-2">
              <div className="custom-select">
                <select
                  value={category}
                  onChange={handleCategoryChange}
                  id="category-vehicles"
                  className="custom-select"
                  aria-label="Default select example"
                >
                  <option className="dropdown-item2" value="All">
                    Filter By
                  </option>
                  {vehicleAllCategories.map((category, index) => (
                    <option
                      className="dropdown-item2"
                      value={category}
                      key={index}
                    >
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="down_note"></div>
            </div>
            <div className="col-md-8">
              <div className="search-container"></div>
            </div>
            <div className="col-md-2">
              <div className="custom-select-sort">
                <select
                  value={sort}
                  onChange={handleSortChange}
                  className="custom-select-sort"
                  id="category-vehicles"
                >
                  <option className="dropdown-item2" value="All">
                    Newly Added
                  </option>
                  <option className="dropdown-item2" value="hightolow">
                    Price: High to Low
                  </option>
                  <option className="dropdown-item2" value="lowtohigh">
                    Price: Low to High
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="container full-width d-flex mt-4 p-2 mb-5 pt-1 custom-vehicle-not-available">
          {filteredVehicles.length <= 0 ? (
            <div className="row pt-2 mb-2">
              <div className="text-center">
              <h1 className="fw-bold">Vehicle Not Available!</h1>
                <br />
                <Link to={`/`} className="btn btn-success btn-lg"> Go Home </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                {filteredVehicles
                  .slice(startVehicleListIndex, endVehicleListIndex)
                  .map((vehicles, key) => (
                    <div
                      className="col-md-6 col-lg-6 mb-carlist-padding"
                      key={key}
                    >
                      <div className="card mb-3 custom-card-vehicle">
                        <div className="row vehicle-information-col">
                          <div className="col-md-4">
                            <img
                              src={`https://backend.carsinafrica.com/uploads/${vehicles.vehicle_image}`}
                              className="carlist-image"
                              alt="Carlist Image"
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body car-list-data">
                              <h5 className="card-title font-weight-bold">
                                <a>
                                  <b>
                                    {vehicles.make.charAt(0).toUpperCase() +
                                      vehicles.make.slice(1)}{" "}
                                    {vehicles.model.charAt(0).toUpperCase() +
                                      vehicles.model.slice(1)}
                                  </b>
                                </a>
                              </h5>
                              <i className="bi bi-geo-alt-fill"></i>{" "}
                              <span>{paddress}</span>
                              <div className=""></div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_air-conditioning.svg"
                                  }
                                  alt="AC"
                                  className="mr-1"
                                />
                                AC
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/arcticonscar.svg"
                                  }
                                  alt="Sedan"
                                  className="mr-1"
                                />
                                <span className="vehicle-span">
                                  {vehicles.vehicletype}
                                </span>
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_petrol-pump-outline.svg"
                                  }
                                  alt="Petrol"
                                  className="mr-1"
                                />
                                {vehicles.engine_type}
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_seat-passenger.svg"
                                  }
                                  alt="4 Seater"
                                  className="mr-1"
                                />
                                {vehicles.seats} Seater
                              </div>
                              <hr className="my-3" />
                              <div className="row">
                                <div className="col-md-8">
                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <div className="chip me-0">
                                        {vehicles.per_km_fare === 0 ? (
                                          <b className="custom-bold">
                                            Allotted km: Unlimited km/
                                            {service === "transfer"
                                              ? "way"
                                              : "day"}
                                          </b>
                                        ) : (
                                          <b className="custom-bold">
                                            Allotted km: {vehicles.alloted_kms}
                                            km/
                                            {service === "transfer"
                                              ? "way"
                                              : "day"}
                                          </b>
                                        )}
                                        <div className=""></div>
                                        {vehicles.per_km_fare === 0 ? null : (
                                          <>
                                            <b className="custom-bold">
                                              Extra km: $
                                              {decimalsToRound(
                                                parseFloat(vehicles.per_km_fare)
                                              )}
                                              /km
                                            </b>
                                            <div className=""></div>
                                          </>
                                        )}
                                        <b>
                                          {decimalsToRound(
                                            parseFloat(vehicles.base_fare)
                                          )}{" "}
                                          USD/
                                          {service === "transfer"
                                            ? "way"
                                            : "day"}
                                        </b>{" "}
                                        |
                                        <b className="custom-bold-left">
                                          {decimalsToRound(
                                            parseFloat(
                                              vehicles.currency_base_fare
                                            )
                                          )}{" "}
                                          {vehicles.currency}/
                                          {service === "transfer"
                                            ? "way"
                                            : "day"}
                                        </b>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-4 book-now-btn">
                                  <Link
                                    className="btn btn-primary py-2 custom-link-button"
                                    to={
                                      userLoggedData.api_token === null
                                        ? `vehicle-booking/${vehicles.tid}/${vehicles.vid}/${vehicles.search_id}/user-login`
                                        : `vehicle-booking/${vehicles.tid}/${vehicles.vid}/${vehicles.search_id}`
                                    }
                                  >
                                    Book Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="pagination">
                <ul className="pagination">
                  <li className="page-item custom-page-item">
                    <button
                      className="page-link btn btn-success"
                      onClick={() =>
                        handlePageVehicleListChange(vehicleListPage - 1)
                      }
                      disabled={vehicleListPage === 1}
                    >
                      {"<"}
                    </button>
                  </li>
                  <li className="page-item custom-page-item">
                    <span className="page-link">{vehicleListPage}</span>
                  </li>
                  <li className="page-item custom-page-item">
                    <button
                      className="page-link btn btn-success"
                      onClick={() =>
                        handlePageVehicleListChange(vehicleListPage + 1)
                      }
                      disabled={endVehicleListIndex >= filteredVehicles.length}
                    >
                      {">"}
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
  // END -> vehicle Listing Page

  // START -> Savaari vehicle Listing Page
  else if (
    vehicleListProvider === "savaari" &&
    vehicleListFlagger === true &&
    vehicleListLoader === false
  ) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={
              process.env.PUBLIC_URL + "/images/back-shot-white-car-list.jpg"
            }
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Available Cars</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>

        <div className="container full-width d-flex mt-4 p-2 mb-5 pt-1 custom-vehicle-not-available">
          {savaariVehicles.length <= 0 ? (
            <div className="row pt-2 mb-2">
              <div className="text-center">
                <h1 className="fw-bold">Vehicle Not Available!</h1>
                <br />
                <Link to={`/`} className="btn btn-success btn-lg"> Go Home </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                {savaariVehicles.map((vehicles, key) => (
                  <div
                    className="col-md-6 col-lg-6 mb-carlist-padding"
                    key={key}
                  >
                    <div className="card mb-3 custom-card-vehicle">
                      <div className="row vehicle-information-col">
                        <div className="col-md-4">
                          <img
                            src={`${vehicles.carImageLarge}`}
                            className="carlist-image"
                            alt="Carlist Image"
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body car-list-data">
                            <h5 className="card-title font-weight-bold">
                              <a>
                                <b>{vehicles.carName}</b>
                              </a>
                            </h5>
                            <i className="bi bi-geo-alt-fill"></i>{" "}
                            <span>{paddress}</span>
                            <div className=""></div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_air-conditioning.svg"
                                }
                                alt="AC"
                                className="mr-1"
                              />
                              AC
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/arcticonscar.svg"
                                }
                                alt="Sedan"
                                className="mr-1"
                              />
                              <span className="vehicle-span">
                                {vehicles.carNameAlias}
                              </span>
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_petrol-pump-outline.svg"
                                }
                                alt="Petrol"
                                className="mr-1"
                              />
                              {/* {vehicles.engine_type} */}
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_seat-passenger.svg"
                                }
                                alt="4 Seater"
                                className="mr-1"
                              />
                              {vehicles.seatCapacity} Seater
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                              <div className="col-md-8">
                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    <div className="chip me-0">
                                      <b
                                        style={{
                                          fontSize: "15px",
                                          color: "#F57B20",
                                        }}
                                      >
                                        {vehicles.package}
                                      </b>
                                      <br />
                                      <div className=""></div>
                                      {vehicles.rates.discounted ? (
                                        <b className="custom-bold">
                                          {decimalsToRound(
                                            parseFloat(
                                              vehicles.rates.discounted
                                                .totalAmount
                                            ) * savaariServiceTax
                                          )}{" "}
                                          INR
                                        </b>
                                      ) : (
                                        <b className="custom-bold">
                                          {decimalsToRound(
                                            parseFloat(
                                              vehicles.rates.regular.totalAmount
                                            ) * savaariServiceTax
                                          )}{" "}
                                          INR
                                        </b>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4 book-now-btn">
                                <Link
                                  className="btn btn-primary py-2 custom-link-button"
                                  to={
                                    userLoggedData.api_token === null
                                      ? `vehicle-booking/svri/${vehicles.carId}/user-login`
                                      : `vehicle-booking/svri/${vehicles.carId}`
                                  }
                                >
                                  Book Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="pagination">
                <ul className="pagination">
                  <li className="page-item custom-page-item">
                    <button
                      className="page-link btn btn-success"
                      onClick={() =>
                        handlePageVehicleListChange(vehicleListPage - 1)
                      }
                      disabled={vehicleListPage === 1}
                    >
                      {"<"}
                    </button>
                  </li>
                  <li className="page-item custom-page-item">
                    <span className="page-link">{vehicleListPage}</span>
                  </li>
                  <li className="page-item custom-page-item">
                    <button
                      className="page-link btn btn-success"
                      onClick={() =>
                        handlePageVehicleListChange(vehicleListPage + 1)
                      }
                      disabled={endVehicleListIndex >= filteredVehicles.length}
                    >
                      {">"}
                    </button>
                  </li>
                </ul>
              </div> */}
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
  // END -> Savaari vehicle Listing Page

  // START -> Iway vehicle Listing Page
  else if (
    vehicleListProvider === "iway" &&
    vehicleListFlagger === true &&
    vehicleListLoader === false
  ) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={
              process.env.PUBLIC_URL + "/images/back-shot-white-car-list.jpg"
            }
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Available Cars</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="container full-width d-flex mt-4 p-2 mb-5 pt-1 custom-vehicle-not-available">
          {iWayVehicles.length <= 0 ? (
            <div className="row pt-2 mb-2">
              <div className="text-center">
              <h1 className="fw-bold">Vehicle Not Available!</h1>
                <br />
                <Link to={`/`} className="btn btn-success btn-lg"> Go Home </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                {iWayVehicles.map((vehicles, key) => (
                  <div
                    className="col-md-6 col-lg-6 mb-carlist-padding"
                    key={key}
                  >
                    <div className="card mb-3 custom-card-vehicle">
                      <div className="row vehicle-information-col">
                        <div className="col-md-4">
                          <img
                            src={`https://sandbox.iway.io/images/cars/${vehicles.car_class.photo}`}
                            className="img-fluid carlist-image"
                            alt="Carlist Image"
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body car-list-data">
                            <h5 className="card-title font-weight-bold">
                              <a>
                                <b>{vehicles.car_class.models[0]}</b>
                              </a>
                            </h5>
                            <i className="bi bi-geo-alt-fill"></i>{" "}
                            <span>{paddress}</span>
                            <div className=""></div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_air-conditioning.svg"
                                }
                                alt="AC"
                                className="mr-1"
                              />
                              AC
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/arcticonscar.svg"
                                }
                                alt="Sedan"
                                className="mr-1"
                              />
                              <span className="vehicle-span">
                                {vehicles.car_class.title}
                              </span>
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_petrol-pump-outline.svg"
                                }
                                alt="Petrol"
                                className="mr-1"
                              />
                              {vehicles.car_class.luggage_capacity}
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_seat-passenger.svg"
                                }
                                alt="4 Seater"
                                className="mr-1"
                              />
                              {vehicles.car_class.capacity} Seater
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                              <div className="col-md-8">
                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    <div className="chip me-0">
                                      <b
                                        style={{
                                          fontSize: "15px",
                                          color: "#F57B20",
                                        }}
                                      >
                                        {/* {vehicles.package} */}
                                      </b>
                                      <br />
                                      <div className=""></div>
                                      <b className="custom-bold">
                                        {vehicles.price} USD
                                      </b>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4 book-now-btn">
                                <Link
                                  className="btn btn-primary py-2 custom-link-button"
                                  to={
                                    userLoggedData.api_token === null
                                      ? `vehicle-booking/iway/true/${vehicles.price_id}/user-login`
                                      : `vehicle-booking/iway/true/${vehicles.price_id}`
                                  }
                                >
                                  Book Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
  // END -> Iway vehicle Listing Page

  // START -> Vehicle Not Found Page//
  else {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={
              process.env.PUBLIC_URL + "/images/back-shot-white-car-list.jpg"
            }
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Available Cars</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        {/* Car Results thorugh map*/}
        <div className="rn-section rn-car-search-results custom-section-rn">
          <div>
            <div className=""></div>
            <div>
              <h1>Vehicles Not Available!</h1>
              <div className=""></div>
              <Link
                className="btn btn-success btn-lg btn-shadow custom-link-rn"
                to={`/`}
              >
                Go Home
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> Vehicle Not Found Page
}
