import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import { Link } from "react-router-dom";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Faq3 from "../../ciaComponents/faqs/Faqs3.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";

export default function FleetManagement() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("fleet-management");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  // For Accordian
  const [showAll, setShowAll] = useState(false);
  const faqs = [
    {
      question: "1. What is fleet management?",
      answer:
        "Fleet management involves overseeing a company's vehicle fleet to optimize operations, reduce costs, ensure compliance, and enhance safety. Our services include vehicle tracking, maintenance management, fuel management, and driver management.",
    },
    {
      question:
        "2. What types of vehicles can be managed under your fleet management services?",
      answer:
        "We can manage a diverse range of vehicles, including cars, vans, trucks, and specialized vehicles, tailored to meet the specific needs of your business.",
    },
    {
      question: "3. How can I enroll my fleet in your management program?",
      answer:
        "Enrolling your fleet is easy! Simply contact our sales team or fill out the inquiry form on our website. We will assess your needs and provide a customized fleet management solution.",
    },
    {
      question: "4. What technology do you use for fleet tracking?",
      answer:
        "We utilize advanced GPS tracking systems that provide real-time monitoring of vehicle locations, routes, and performance metrics, helping you make informed decisions about your fleet operations.",
    },
    {
      question: "5. How does your maintenance management work?",
      answer:
        "Our maintenance management services include scheduling regular maintenance, tracking service history, and managing repairs to ensure all vehicles remain in optimal condition. We help prevent breakdowns and extend vehicle lifespan.",
    },
    {
      question: "6. Can you help with fuel management?",
      answer:
        "Yes! We offer fuel management solutions that monitor fuel consumption, identify inefficiencies, and provide reports to help reduce fuel costs and improve overall efficiency.",
    },
    {
      question:
        "7. What are the benefits of using your fleet management services?",
      answer:
        "The benefits include improved efficiency, reduced operational costs, enhanced safety, better compliance with regulations, and increased vehicle lifespan. Our solutions are designed to streamline operations and provide valuable insights.",
    },
    {
      question: "8. How do you ensure driver safety and compliance?",
      answer:
        "We implement driver management programs that include training, performance monitoring, and compliance checks to promote safe driving practices and adherence to legal requirements.",
    },
    {
      question: "9. Can you provide customized reporting for my fleet?",
      answer:
        "Yes, we offer customizable reporting options that provide insights into various aspects of your fleet, including vehicle performance, fuel consumption, maintenance schedules, and driver behavior.",
    },
    {
      question:
        "10. What is the minimum number of vehicles required to use your fleet management services?",
      answer:
        "There is no strict minimum, but we generally recommend managing at least a few vehicles to maximize the benefits of our services. Please contact us to discuss your specific needs.",
    },
    {
      question: "11. How do you handle emergencies or breakdowns?",
      answer:
        "We have a dedicated support team available 24/7 to assist with emergencies or breakdowns. Our service includes roadside assistance and coordination for vehicle repairs to minimize downtime.",
    },
    {
      question:
        "12. Is there a contract term for your fleet management services?",
      answer:
        "We offer flexible contract terms to suit your business needs. You can choose from short-term to long-term agreements based on your requirements.",
    },
    {
      question:
        "13. What are the costs associated with your fleet management services?",
      answer:
        "Pricing depends on the size of your fleet and the specific services required. We provide transparent pricing and work with you to create a customized plan that fits your budget.",
    },
    {
      question: "14. How do I access my fleet management data?",
      answer:
        "You can access your fleet management data through our user-friendly online portal or mobile app, providing you with real-time insights and reports at your fingertips.",
    },
    {
      question:
        "15. Can you assist with compliance and regulatory requirements?",
      answer:
        "Yes, our team is knowledgeable about industry regulations and can help ensure your fleet remains compliant with local laws and standards.",
    },
  ];

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL + "/images/fleet-management-service-banner.jpg"
          }
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Fleet Management</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3 ">
              <h1 className=" fw-bold  cia-service-heading mb-3">
                Fleet Management{" "}
              </h1>

              <div className="underline mb-3"></div>
              <p className="mb-4 fw-normal cia-service-discription">
                Maximize efficiency and minimize costs with Cars in Africa s
                comprehensive Fleet Management services. Our professional
                solutions enable companies to effectively manage their fleet of
                vehicles, therefore guaranteeing the best performance and
                dependability. <br />
                <br /> From routine maintenance and tracking to fuel management
                and compliance, we handle every element of fleet operations to
                improve output. Real-time data from our advanced systems enable
                you to make wise decisions while minimizing operational costs
                and downtime. <br />
                <br />
                Work with Cars in Africa for a customized fleet management
                system that streamlines your transportation logistics and
                propels success!
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>

            <div className="col-md-6 mt-3 zoom-out">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-2.png"
                  }
                  alt="Large"
                  className="img-fluid px-1 "
                />
              </div>
              <div className="d-flex ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-3.png"
                  }
                  alt="Small 1"
                  className="img-fluid w-50 px-1 "
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-4.png"
                  }
                  alt="Small 2"
                  className="img-fluid w-50 px-1 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">
            Key Features
          </h2>
          <div className="row d-flex justify-content-center mx-2">
            <div className=" key-features-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row  ">
            {/* Card 1 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/cost-effective-solutions.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Cost-Effective Solutions
                </h5>
                <p className="why-choose-card-text">
                  Real-time data from our advanced systems enable you to make
                  wise decisions while minimizing operational costs
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/fuel-management.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Fuel Management</h5>
                <p className="why-choose-card-text">
                  Implement fuel tracking and management solutions to optimize
                  consumption and reduce operational costs effectively.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/maintenance-scheduling.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Maintenance Scheduling
                </h5>
                <p className="why-choose-card-text">
                  Stay ahead with automated maintenance reminders, ensuring all
                  vehicles are regularly serviced and in optimal condition.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/comprehensive-fleet-monitoring.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Comprehensive Fleet Monitoring
                </h5>
                <p className="why-choose-card-text">
                  Utilize advanced tracking systems for real-time monitoring of
                  vehicle locations, ensuring efficient fleet operations.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 5 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/custom-reporting.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Custom Reporting</h5>
                <p className="why-choose-card-text">
                  Access customizable reports that provide insights into fleet
                  performance, costs, and operational efficiency for informed
                  decisionmaking.
                </p>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/Safety compliance-monitoring.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Safety Compliance Monitoring
                </h5>
                <p className="why-choose-card-text">
                  Ensure all vehicles meet safety regulations and compliance
                  standards through regular audits and inspections.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Want To Simplify Your Fleet Management?"
        buttonText="Contact Us"
        redirection="/contact-us"
      />

      <Faq3 faqs={faqs} title="FAQ's" />

      <Annimations />
      <Footer />
    </>
  );
}
