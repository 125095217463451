import { useEffect } from "react";

export default function Animations() {
  useEffect(() => {
    let lastScrollTop = 0;

    // Function to detect if an element's middle is in the middle of the viewport
    function isInMiddleViewport(element) {
      const rect = element.getBoundingClientRect();
      const elementMid = rect.top + rect.height / 2;
      const viewportMid = window.innerHeight / 1.2;

      return elementMid >= viewportMid - 50 && elementMid <= viewportMid + 50;
    }

    // Function to detect if an element is below the target detection point (90% down the screen)
    function isBelowMiddleViewport(element) {
      const rect = element.getBoundingClientRect();
      const viewportTarget = window.innerHeight * 0.9;

      return rect.top + rect.height / 2 > viewportTarget;
    }

    // Function to handle scroll and apply animations
    function handleScroll() {
      const slideUpElements = document.querySelectorAll(".slide-up");
      const zoomInElements = document.querySelectorAll(".zoom-in");
      const zoomOutElements = document.querySelectorAll(".zoom-out");

      // Determine scroll direction
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollingDown = currentScrollTop > lastScrollTop;
      lastScrollTop = currentScrollTop;

      // Handle animations for slide-up elements
      slideUpElements.forEach((element) => {
        if (isInMiddleViewport(element)) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.add("show");
          element.classList.remove("hide");
        } else if (isBelowMiddleViewport(element) && !scrollingDown) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.remove("show");
          element.classList.add("hide");
        }
      });

      // Handle animations for zoom-in elements
      zoomInElements.forEach((element) => {
        if (isInMiddleViewport(element)) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.add("zoom-show");
          element.classList.remove("zoom-hide");
        } else if (isBelowMiddleViewport(element) && !scrollingDown) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.remove("zoom-show");
          element.classList.add("zoom-hide");
        }
      });

      // Handle animations for zoom-out elements
      zoomOutElements.forEach((element) => {
        if (isInMiddleViewport(element)) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.add("zoom-out-show");
          element.classList.remove("zoom-out-hide");
        } else if (isBelowMiddleViewport(element) && !scrollingDown) {
          element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
          element.classList.remove("zoom-out-show");
          element.classList.add("zoom-out-hide");
        }
      });
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
}
