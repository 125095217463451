import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import OurServices from "../../components/Services/OurServices.js";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";
export default function Services() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("services");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);


  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/service-main-page-hero.jpg"
          }
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Services</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <OurServices />

      <section className="home-testimonial">
        <div className="container-fluid">
          <div className="pricing-header p-3 pb-md-2 mx-auto text-center position-relative">
            <h1 className="display-5 fw-bold text-black">Testimonials</h1>
          </div>
          <div className="row d-flex justify-content-center testimonial-pos">
            <div className="col-md-12 d-flex justify-content-center text-black">
              <h2 className="text-black">
                What People Are Saying About Cars In Africa
              </h2>
            </div>
          </div>
          <div className="container-fluid py-5" id="mobile-block">
            <div className="row">
              <div className="col-md-12 col-lg-12 mx-auto">
                <div className="lc-block py-4 text-center">
                  <div
                    id="carouselTestimonial"
                    className="carousel slide pb-4"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “My South Africa trip was amazing with Cars In
                                Africa! Elegant car, excellent chauffeur
                                service. Highly recommend for hassle-free
                                travel"
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2 ">
                                <img
                                  className="tm-people rounded-circle me-2 "
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-3.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Mark Thompson
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “Whether business or pleasure, Cars In Africa
                                never disappoints. Stress-free commute with
                                their reliable employee transportation. I'll
                                book again!"
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-1.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- John Smith</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “I had a fantastic time hiring a car from the
                                CIA. The procedure was quick and
                                straightforward, and the personnel was courteous
                                and knowledgeable."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar02jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Harris Johnson
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-justify mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-justify">
                                “Thank you very much for everything! I hope to
                                hire a car with your amazing service! I am very
                                happy with the service via email, and corporate
                                meetings."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar03jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- Durso Raeen</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “I trust Cars In Africa for all my travel needs.
                                Their commitment to satisfaction ensures a safe
                                and comfortable journey every time."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/Testimonial-2.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">
                                  - Sarah Onyango
                                </div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                              <div className="d-flex justify-content-left align-items-center mb-2"></div>
                              <div class="tour-text color-grey-3 justify-content-center">
                                “This company was the able to provide me with a
                                car within the hour and the staff was extremely
                                accommodating and made the process stress-free."
                              </div>
                              <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                                <img
                                  className="tm-people rounded-circle me-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/avatar01jpg@2x.png"
                                  }
                                  alt="Avatar"
                                  width={48}
                                  height={48}
                                />
                                <div></div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-muted">- James Raabin</div>
                                <div className="star-icon text-warning">
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                  <i className="bi bi-star-fill" />
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 text-center mt-3">
                      <a
                        className="carousel-control-prev position-relative d-inline me-4"
                        href="#carouselTestimonial"
                        data-bs-slide="prev"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="text-dark"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                        <span className="visually-hidden">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next position-relative d-inline"
                        href="#carouselTestimonial"
                        data-bs-slide="next"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="text-dark"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="visually-hidden">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* /col */}
            </div>
          </div>

          {/* Mobile Slider */}
          <div className="row" id="mobile-block2">
            <div className="col-md-12 col-lg-12 mx-auto">
              <div className="lc-block py-4 text-center">
                <div
                  id="carouselTestimonial"
                  className="carousel slide pb-4"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “My South Africa trip was amazing with Cars In
                              Africa! Elegant car, excellent chauffeur service.
                              Highly recommend for hassle-free travel"<br></br>
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-3.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Mark Thompson</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “Whether business or pleasure, Cars In Africa
                              never disappoints. Stress-free commute with their
                              reliable employee transportation. I'll book
                              again!"
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-1.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- John Smith</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “I had a fantastic time hiring a car from the CIA.
                              The procedure was quick and straightforward, and
                              the personnel was courteous and knowledgeable."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar02jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Harris Johnson</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “Thank you very much for everything! I hope to
                              hire a car with your amazing service! I am very
                              happy with the service via email, and corporate
                              meetings."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar03jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Durso Raeen</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “I trust Cars In Africa for all my travel needs.
                              Their commitment to satisfaction ensures a safe
                              and comfortable journey every time."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Testimonial-2.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- Sarah Onyango</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-12 col-lg-4 testimonial-height">
                          <div className="tour-desc bg-white p-3 rounded shadow testimonial-height">
                            <div className="d-flex justify-content-left align-items-center mb-2" />
                            <div className="tour-text color-grey-3 justify-content-center">
                              “This company was the able to provide me with a
                              car within the hour and the staff was extremely
                              accommodating and made the process stress-free."
                            </div>
                            <div className="d-flex justify-content-left align-items-center pt-2 pb-2">
                              <img
                                className="tm-people rounded-circle me-2"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/avatar01jpg@2x.png"
                                }
                                alt="Avatar"
                                width={48}
                                height={48}
                              />
                              <div></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-muted">- James Raabin</div>
                              <div className="star-icon text-warning">
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                                <i className="bi bi-star-fill" />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 text-center mt-3">
                    <a
                      className="carousel-control-prev position-relative d-inline me-4"
                      href="#carouselTestimonial"
                      data-bs-slide="prev"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="text-dark"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* Previous icon */}
                      </svg>
                      <span className="visually-hidden">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next position-relative d-inline"
                      href="#carouselTestimonial"
                      data-bs-slide="next"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="text-dark"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* Next icon */}
                      </svg>
                      <span className="visually-hidden">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Need A Reliable Car Rental For Your Next Trip?"
        buttonText="Contact Us"
        redirection="/contact-us"
      />

      <Annimations />
      <Footer />
    </>
  );
}
