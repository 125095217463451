import React from "react";
import { Link } from "react-router-dom";

const CtaComp = ({ tagline, buttonText, redirection }) => {
  return (
    <section className="car-looking">
      <div className="image-rent">
        <div className="container full-width cta-mobile-container">
          <div className="row align-items-center text-left">
            <div className="col-md-6">
              {/* <img
                src={process.env.PUBLIC_URL + "/images/services_car.png"}
                width="100%"
                alt="services"
                className=" zoom-in"
              /> */}
            </div>
            <div className="col-md-6 slide-up">
              <h2 className="pt-2 fw-bold text-white ">{tagline}</h2>
              <div className="pt-4 mb-4">
                <Link to={redirection}>
                  <button
                    type="button"
                    className="btn btn-success btn-lg rounded fw-bold "
                  >
                    {buttonText}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaComp;
