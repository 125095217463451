import React from "react";
import "./OurServices.css";
import { Link } from "react-router-dom";





const OurServices = () => {
  return (
    <section className="py-4">
      <div className="container ">
        <div className="pt-5">
          <h2 class="text-center mb-4 cia-our-services-heading">
            Your Trusted Car Rental & Fleet Management Partner
          </h2>
          <p className="fw-normal text-center">
            We at Cars in Africa being one of the top car rental companies,
            offer an extensive range of vehicle solutions to accommodate your
            individual requirements, whether they pertain to personal travel,
            business use, or corporate use. Our customized services guarantee a
            seamless and adaptable experience to rent your car.
          </p>
          <div class="row d-flex justify-content-center mx-2">
            <div class="cia-services-underline "></div>
          </div>
        </div>
        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first  zoom-out">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/chauffeur-drive-service-hero-image.jpg"
              }
              alt="Chauffeur Drive"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Chauffeur Drive</h1>
              <p className="cia-services-discription ">
                Our skilled chauffeurs are the ideal choice for long-distance
                excursions, corporate travel, and airport transfers. Relax
                during your journey.
              </p>
              <Link to="chauffeur-drive" className="btn cia-contact-us-btn">
                Know More &nbsp; <i class="bi bi-info-circle"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-last order-md-last order-lg-last order-xl-last  zoom-out">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/services-images/main-service-2.png"
              }
              alt="Self Drive"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-first order-md-first order-lg-first order-xl-first">
            <div>
              <h1 className="fw-bold pb-3">Self Drive</h1>
              <p className="cia-services-discription">
                Select from our extensive fleet of vehicles for either
                short-term or long-term rentals. And experience the freedom to
                explore at your leisure with our Self-Drive services.
              </p>
              <Link to="self-drive" className="btn cia-contact-us-btn">
                Know More &nbsp; <i class="bi bi-info-circle"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first  zoom-out">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/services-images/main-service-3.png"
              }
              alt="Car Lease"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Car Lease</h1>
              <p className="cia-services-discription">
                Cost-effective leasing options for businesses, tailored to fit
                your needs. Unlock flexibility and convenience with our Car
                Lease Solutions.
              </p>
              <Link to="car-lease" className="btn cia-contact-us-btn">
                Know More &nbsp; <i class="bi bi-info-circle"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-last order-md-last order-lg-last order-xl-last  zoom-out">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/services-images/main-service-4.png"
              }
              alt="Employee Transportation"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-first order-md-first order-lg-first order-xl-first">
            <div>
              <h1 className="fw-bold pb-3">Employee Transportation</h1>
              <p className="cia-services-discription">
                Organisations can depend on reliable and efficient
                transportation solutions to guarantee the safety of their
                employees during their commute.
              </p>
              <Link
                to="employee-transportation"
                className="btn cia-contact-us-btn"
              >
                Know More &nbsp; <i class="bi bi-info-circle"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first  zoom-out">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/fleet-management-service.jpg"
              }
              alt="Corporate Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Fleet Management</h1>
              <p className="cia-services-discription">
                The comprehensive management of corporate fleets, which ensures
                the complete control, optimization, and cost-efficiency of your
                vehicle operations.
              </p>
              <Link to="fleet-management" className="btn cia-contact-us-btn">
                Know More &nbsp; <i class="bi bi-info-circle"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
