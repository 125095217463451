import React from "react";

const CancellationPolicyComp = () => {
  return (
    <>
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/cancellation_policy.jpg"}
          className="img-fluid"
          alt="cancellation policy"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Cancellation Policy</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width">
        <div className="row">
          <div className="container py-5">
            <div className="row">
              <div className="shadow-sm p-3  bg-body rounded">
                <li>Prior to 48 Hours - No Cancellation Charge</li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3  bg-body rounded">
                <li>
                  Within 24-48 hours from rental time - No Cancellation Charge
                </li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3  bg-body rounded">
                <li>Within 0 - 24 hours from rental time - 100%</li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3  bg-body rounded">
                <li>No show - 100%</li>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationPolicyComp;
