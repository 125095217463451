import React, { useState } from "react";

const Faq3 = ({ faqs, title = "FAQ's" }) => {
  const [showAll, setShowAll] = useState(false);

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);
  return (
    <section className="faq-container py-5">
      <div className="container">
        <h1 className="text-center fw-bold mb-3">{title}</h1>
        <div className="row d-flex justify-content-center mx-2">
          <div className="heading-with-underline mb-3"></div>
        </div>

        {/* <div className="row ">
          <div className="d-flex justify-content-between"><span className="display-6 fw-bold">FAQ's <div className="row d-flex justify-content-center mt-3 mx-2">
          <div className="heading-with-underline mb-3"></div>
        </div></span> <span><div className="text-center mt-4">
          <button
            className="btn btn-primary"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "View Less" : "View All"}
          </button>
        </div></span></div>
        </div> */}

        <div className="accordion mt-4" id="faqAccordion">
          {visibleFAQs.map((faq, index) => (
            <div className="accordion-item " key={index}>
              <h2 className="accordion-header" id={`heading-${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${index}`}
                >
                  {faq.question}
                </button>
              </h2>
              <div
                id={`collapse-${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading-${index}`}
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <button
            className="btn accordian-viewMore-btn"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "View Less" : "View All"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Faq3;
